import Header from './components/Header'
import React, {useState} from 'react'
import NumberFormat from 'react-number-format';
import html2pdf from 'html2pdf-jspdf2'


function App() {

  const [currentAge,setCurrentAge] = useState()
  const [currentIncome,setCurrentIncome] = useState()
  const [annualSavingsAmount,setAnnualSavingsAmount] = useState()
  const [currentRetirementAssets,setCurrentRetirementAssets] = useState()
  const [averageAnnualRateOfReturn] = useState(6)
  const [requiredRetirementAssets,setRequiredRetirementAssets] = useState()
  const [years, setYears] = useState()

 

  function calcPV(inPMT, inFV, inNR, inNP, inC) {
    var outPV = inFV*Math.pow((1 + inNR/(100*inC)),(-inNP));
    if (inNR === 0) {
      outPV = outPV + inPMT*inNP;
    } else {
      outPV = outPV + inPMT*((1-(Math.pow((1 + inNR/(100*inC)),(-inNP))))/(inNR/(100*inC)));
    }
    return outPV;
  }

  function calcNP(inPMT, inPV, inFV, inNR, inC) {
    var	outNP = 5; // initial guess
    var lastNP = outNP;
    var	thePV1, thePV2, theDeriv;
    var	theH = 0.001;
    var	i = 1;
    var	theZeros = 0;

    if (inNR <= 0) { // should throw an exception
      alert("The Number of Periods cannot be computed.");
      return outNP = "";
    }
    if (inFV === 0) {
      theZeros++;
    }	
    if (inPMT === 0) {
      theZeros++;
    }	
    if (inPV === 0) {
      theZeros++;
    }
    if (currentAge === ""){
      alert("Please enter your Current Age.")
      return outNP = "";
    }
    if (theZeros >= 2) { // should throw an exception
      alert("The Number of Periods cannot be computed.");
      return outNP = "";
    }
    if ((inPV > 0) && (inPMT >= 0) && (inFV >= 0)) {
      alert("The Number of Periodscannot be computed.");
      return outNP = "";
    }
    if ((inPV === 0) && (inPMT >= 0) && (inFV >= 0)) {
      alert("The Number of Periods cannot be computed.");
      return outNP = "";
    }
    inPV *= -1;
    if ((inPV === inFV) && ((inNR/(100*inC))*inFV === inPMT)) { // should throw an exception
      alert("The Number of Periods is not unique.");
      return outNP = "";// outNP can be any number
    }
    do {
      thePV1 = calcPV(inPMT,inFV,inNR,outNP,inC) - inPV;
      theDeriv = ((calcPV(inPMT,inFV,inNR,outNP+theH,inC) - inPV) - thePV1)/theH;
      thePV2 = thePV1;
      //if (theDeriv === 0) { // should throw an exception
      //	return outN;
      //}
      lastNP = outNP;
      outNP = outNP - thePV1/theDeriv;
      if (i > 200) { // should throw an exception
        alert("The Number of Periods cannot be computed.");
        return outNP = "";
      }
      i++;
    } while (Math.abs(thePV2) > 0.0001);
    // return lastNP;
    console.log((parseInt(currentAge) + parseFloat(lastNP)).toFixed(2))	
    // setYears((parseInt(currentAge) + parseFloat(lastNP)).toFixed(2))
    setYears((parseInt(currentAge) + parseFloat(lastNP)).toFixed(2))

  }
  const getPdf = () => {
    let opt = {
      margin: .10,
      filename: "Retirement Calculator.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 3},
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    };
    let page = document.getElementById("app")
    html2pdf().set(opt).from(page).save()
  }
  const calc = ()=> {
    calcNP(annualSavingsAmount,(currentRetirementAssets + annualSavingsAmount),requiredRetirementAssets,averageAnnualRateOfReturn,1) 
    console.log(`
    currentAge=${currentAge}\n
    currentIncome=${currentIncome}\n
    annualSavingsAmount=${annualSavingsAmount}\n
    currentRetirementAssets=${currentRetirementAssets}\n
    requiredRetirementAssets=${requiredRetirementAssets}\n
    years=${years}\n



    `) 
  }
  // useEffect(() => {
  //   return() => {
  //     calcNP(annualSavingsAmount,currentRetirementAssets,requiredRetirementAssets,averageAnnualRateOfReturn,1)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentAge,currentIncome,currentRetirementAssets,annualSavingsAmount,averageAnnualRateOfReturn,requiredRetirementAssets])


  return (
    <div className="App" id="app">
      <Header/>
      <div className="main">
      <div className="form">
          <div className="group1">
            <div className="field mr-16">
              <label htmlFor="currentAge">Current Age</label>
              <input name="currentAge" type="number" placeholder="Enter Your Current Age"value={currentAge} onChange={(e)=>{
                setCurrentAge(e.target.value)
              }}/>
            </div>
            <div className="field">
              <label htmlFor="freedomAge">Age you would like to be <br></br>Financially Free</label>
              <input name="freedomAge" placeholder="Enter Freedom Age" type="number" onChange={(e)=>{
              }}/>
            </div>
          </div>
          <div className="group2">
            <div className="field mr-16">
              <label htmlFor="currentIncome">Current Income</label>
              <NumberFormat  name="currentIncome" placeholder="Enter Annual Income" value={currentIncome} isNumericString={true} thousandSeparator={true} prefix={'$'} onValueChange={(values)=>{
                setCurrentIncome(parseInt(values.value))
                setRequiredRetirementAssets(-1 * (parseInt(values.value) * 33.33))
              }}/>
            </div>
            <div className="field mr-16">
              <label htmlFor="annualSavingsAmount">Annual Savings Amount</label>
              <NumberFormat  name="annualSavingsAmount" placeholder="Enter Annual Savings"  value={annualSavingsAmount} isNumericString={true} thousandSeparator={true} prefix={'$'} onValueChange={(values)=>{
                setAnnualSavingsAmount(parseInt(values.value))
              }}/>
            </div>
            <div className="field">
              <label htmlFor="currentRetirementAssets">Current Retirement Assets</label>
              <NumberFormat  name="currentRetiremenAssets" placeholder="Enter Current Assets" value={currentRetirementAssets} isNumericString={true} thousandSeparator={true} prefix={'$'} onValueChange={(values)=>{
                setCurrentRetirementAssets(parseInt(values.value))
              }}/>
            </div>
          </div>
        <h3 className="title">Wall Street's Plan For You</h3>
        <div className="group3">
          <div className="field mr-16">
            <label htmlFor="freedomAge">Age of financial freedom</label>
            <NumberFormat disabled name="freedomAge" value={years} isNumericString={true} onValueChange={(values)=>{
            }}/>
          </div>
          <div className="field">
            <label htmlFor="requiredRetirementAssets">Required Retirement Assets **</label>
            <NumberFormat  name="requiredRetirementAssets" 
            disabled
            value={parseInt(requiredRetirementAssets * -1)}
              isNumericString={true} thousandSeparator={true} prefix={'$'} onValueChange={(values)=>{
            }}/>
          </div>
        </div>
        <div className="group4">
           <button onClick={calc}>Calculate</button>
            <button onClick={getPdf}>Download PDF</button>
        </div>
      </div>
      <span className="disclosure"><p>This is a hypothetical example and should not be considered financial advice.</p><p>You should contact a personal advisor to determine how this applies to you before making financial decisions.</p>
  <p>**The Required Retirement Assets is based on the 4% Rule and modified to the 3%.</p> <p><a target="blank" href="https://www.wealthwithoutwallstreet.com/podcast/why-managing-retirement-from-an-investment-portfolio-is-inefficient-w-wade-pfau">Listen to the Interview with Dr.Wade Pfau</a></p></span>
    </div>
  </div>
  );
}



export default App;
