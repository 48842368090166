import React from 'react';
import logo from '../assets/WWW-logo.png'
const Header = () => (
  <div className="header">
    <img id="www-logo" src={logo} alt="Wealth Without Wall Street"/>
    <h1 className="page-title">Retirement Calculator</h1>
    </div>
);

export default Header;
